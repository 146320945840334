import { Pressable, useSx } from 'dripsy';
import React, { useEffect, useState } from 'react';
import { PressableProps, ViewStyle } from 'react-native';
import { Icon } from '../icon/icon';

interface CheckBoxProps extends PressableProps {
  style?: ViewStyle;
  size?: number;
  checked?: boolean;
  onChange?: (v: boolean) => any;
  variant?: 'primary' | 'secondary' | 'white';
}

export function CheckBox({
  style = {},
  size = 24,
  checked = false,
  onChange,
  variant = 'primary',
  ...props
}: CheckBoxProps) {
  const [isChecked, setIsChecked] = useState(false);
  const sx = useSx();
  useEffect(() => {
    setIsChecked(checked);
  }, [checked]);

  useEffect(() => {
    //
  }, [variant]);

  return (
    <Pressable
      style={[
        sx({
          borderColor: '$primary',
          borderWidth: 1,
          width: size,
          height: size,
          borderRadius: 2,
          backgroundColor: '$white',
          alignItems: 'center',
          justifyContent: 'center',
          paddingRight: 0.5,
        }),
        style,
      ]}
      // onPressIn={() => {}}
      onPress={() => {
        onChange && onChange(!isChecked);
      }}
      {...props}
    >
      {isChecked ? (
        <Icon name="checkmark" style={{ width: size, height: size }} />
      ) : (
        <></>
      )}
    </Pressable>
  );
}
