import { H2 } from 'app/components/typography/h2';
import { useSx, View } from 'dripsy';
import React from 'react';
import { createParam } from 'solito';
import AccountLayout from '../account-layout';
import { SignIn } from './signin-form';
import { SignInSignUpLayout } from '../signin-signup-layout';
import { useDim } from 'app/redux/hooks/useDim';
import { useSelector } from 'react-redux';
import { StlLogin } from './stlLogin';
import { getClientConfig } from '../../../redux/memoizedSelectors';

const { useParam } = createParam<{ continue: string }>();
export const UserSignIn = () => {
  const sx = useSx();
  const { width } = useDim();
  const [continueUrl] = useParam('continue');
  const { clientConfig } = useSelector(getClientConfig);

  return (
    <>
      {clientConfig?.loginPage === 'stl' ? (
        <StlLogin />
      ) : (
        <AccountLayout>
          <View
            style={sx({
              maxWidth: ['100%', '100%', '100%', width],
              width: ['100%'],
              alignItems: 'center',
              paddingX: 2,
            })}
          >
            <H2
              style={sx({
                textAlign: 'center',
              })}
            >
              Sign in to {clientConfig?.name}
            </H2>
          </View>

          <SignInSignUpLayout continueUrl={continueUrl} type={'signin'}>
            <SignIn />
          </SignInSignUpLayout>
        </AccountLayout>
      )}
    </>
  );
};
