import { View, useSx, Text } from 'dripsy';
import { Platform } from 'react-native';
import React, { useEffect, useState } from 'react';
import { TextField } from 'app/components/text-field';
import { useRouter } from 'solito/router';
import { validEmail, validPhone } from 'app/util/helper';
import { GoogleSignIn } from 'app/components/google-signin';
import { Button } from 'app/components/button/common-button';

import { connect } from 'react-redux';
import * as userAction from '../../../redux/actions/user.action';
import * as tokenAction from '../../../redux/actions/token.action';
import * as toastAction from '../../../redux/actions/toast.action';
import * as welcomePopupAction from '../../../redux/actions/welcomePopup.action';
import { ToastMessage } from '../../../redux/reducers/toasts';
import { Icon } from 'app/components/icon/icon';
import { createParam } from 'solito';
import userLoginSuccess from 'app/redux/hooks/user-login-success';
import { useFcmWebNotification } from 'app/provider/firebase-messaging';
import { CheckBox } from 'app/components/check-box';
import { makeUseAxiosHook } from 'app/redux/store';
import { useToast } from 'app/redux/hooks/use-toast';
import { getClientConfig, getUser } from '../../../redux/memoizedSelectors';
import Constants from 'expo-constants';

const { useParam } = createParam<{ continue: string }>();

export interface LoginReq {
  phone?: string;
  otp?: string;
  email?: string;
  target?: string;
  userInput?: string;
  firebaseToken?: string;
}

function UserSignInComponent({
  user,
  login,
  setToken,
  addMessage,
  showWelcomePopup,
  clientConfig
}: Readonly<{
  user: any;
  clientConfig:any,
  login: (_user: userAction.LoginReq) => any;
  setToken: (token: string) => any;
  addMessage: (message: ToastMessage) => any;
  showWelcomePopup: () => any;
}>) {
  const [continueUrl] = useParam('continue');
  const sx = useSx();
  const sendMessage = useToast();

  const [showPassword, setShowPassword] = useState<any>(true);
  const [signInWithOTP, setSignInWithOTP] = useState<boolean>(false);
  const [otpSent, setSentOtp] = useState<boolean>(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isResend, setIsResend] = useState<boolean>(false);

  const { generatePushNotificationsTokenForWeb } = useFcmWebNotification();

  const Router = useRouter();
  const { push, replace } = Router;
  const [selectedEditObj, setSelectedEditObj] = useState({
    email: '',
    password: '',
    phone: '',
    otp: '',
    userInput: '',
  });

  const [errorObj, setErrorObj] = useState<any>({});

  const updateValue = (key: string, value: any) => {
    setSelectedEditObj({ ...selectedEditObj, [key]: value });
  };

  const [{ data: otpData, loading: OtpLoading, error: otpError }, sendOtp] =
    makeUseAxiosHook('sso', { manual: true })({
      url: 'send-otp',
      method: 'POST',
    });

  const [
    { data: otpLoginData, loading: loginLoading, error: loginError },
    loginWithOtp,
  ] = makeUseAxiosHook('sso', { manual: true })({
    url: 'otp-login',
    method: 'POST',
  });

  const validateForm = (validateObj: userAction.LoginReq) => {
    let errorList: any = {};

    if (!selectedEditObj.userInput) {
      errorList.userInput = 'Please enter your Email / Phone Number';
    }

    if (!validateObj.type && selectedEditObj.userInput) {
      errorList.userInput = 'Please enter valid email or phone number.';
    }

    if (!validateObj.password) {
      errorList.password = 'Please enter your password';
    }
    if (Object.keys(errorList).length == 0) {
      setErrorObj({});
      return true;
    } else {
      setErrorObj(errorList);
      errorList = {};
      return false;
    }
  };
  const [loading, setLoading] = useState(false);

  const handleSignin = async () => {
    const loginPayload: userAction.LoginReq = {
      strategy: Constants?.expoConfig?.extra?.loginStrategy || 'local' ,
      password: selectedEditObj.password,
    };

    let token: string | undefined = undefined;

    // if (Platform.OS === 'web') {
      token = await generatePushNotificationsTokenForWeb();
    // }
    if(Constants?.expoConfig?.extra?.loginStrategy === 'tcs') {
      loginPayload.strategy = 'tcs';
      loginPayload.type = 'username';
      loginPayload.username = selectedEditObj.userInput.trim();
    }
    else if (validEmail(selectedEditObj.userInput?.trim())) {
      loginPayload.email = selectedEditObj.userInput.trim();
      loginPayload.type = 'email';
    } else if (
      validPhone(selectedEditObj.userInput?.trim()) ||
      validPhone('+91' + selectedEditObj.userInput?.trim())
    ) {
      loginPayload.phone = selectedEditObj.userInput.trim();
      loginPayload.type = 'phone';
      loginPayload.strategy = 'local-phone';
    } else {
      loginPayload.type = '';
    }

    // else {
    //   loginPayload.username = selectedEditObj.userInput.trim();
    //   loginPayload.type = 'username';
    //   loginPayload.strategy = 'local-username';
    // }

    if (token) {
      loginPayload.firebaseToken = token;
    }

    if (!validateForm(loginPayload)) return;
    setLoading(true);
    login(loginPayload);
  };

  const requestObject = () => {
    const validateObj: LoginReq = {};
    if (validEmail(selectedEditObj.userInput)) {
      validateObj.email = selectedEditObj.userInput;
      validateObj.target = 'email';
    } else if (
      validPhone(selectedEditObj.userInput) ||
      validPhone('+91' + selectedEditObj.userInput)
    ) {
      validateObj.phone = selectedEditObj.userInput;
      validateObj.target = 'phone';
    }

    if (otpSent) {
      validateObj.otp = selectedEditObj.otp;
    }

    return validateObj;
  };

  const validateLoginWithOTPForm = (
    validateObj: LoginReq,
    resendCode?: boolean
  ) => {
    let errorList: LoginReq = {};

    if (!selectedEditObj.userInput) {
      errorList.userInput = 'Please enter a email or phone number';
    }

    if (selectedEditObj.userInput && !validateObj.target) {
      errorList.userInput = 'Please enter valid email or phone number';
    }

    if (otpSent && !resendCode) {
      if (!validateObj.otp) {
        errorList.otp = 'Please enter your otp';
      }
    }

    if (Object.keys(errorList).length == 0) {
      setErrorObj({});
      return true;
    } else {
      setErrorObj(errorList);
      errorList = {};
      return false;
    }
  };

  const handleSentOtp = async (resendCode = false) => {
    if (!validateLoginWithOTPForm(requestObject(), resendCode)) return;
    sendOtp({
      data: requestObject(),
    });
  };

  useEffect(() => {
    if (user) {
      if (user.error) {
        setLoading(false);
        setErrorObj({ password: user.error });
        return;
      }
      if (user?._id) {
        setLoading(false);
        // if continueUrl is '/' i.e home, then after login redirect to dashboard
        if (!user.onboardingSkip && !user.preferenceUpdated) {
          if(clientConfig.onboarding){
            replace(
              `/account/on-boarding${continueUrl
                ? `?continue=${continueUrl === '/' ? '/dashboard' : continueUrl
                }`
                : ''
              }`
            );
          } else {
            replace(
              '/dashboard'
            );
          }
        
        } else {
          if (user?.firstLogin === false) {
            showWelcomePopup();
          }
          const isSearchUrl = continueUrl?.includes('search/');
          const searchUrl: any =
            continueUrl?.split('search/')?.[
            continueUrl?.split('search')?.length - 1
            ];
          userLoginSuccess({
            Router,
            continueUrl: isSearchUrl
              ? `/search/${encodeURIComponent(searchUrl)}`
              : continueUrl === '/'
                ? '/dashboard'
                : continueUrl,
          });
        }
      }
    }
  }, [user]);

  const otpLogin = async () => {
    if (!validateLoginWithOTPForm(requestObject())) return;
    let token: string | undefined = undefined;
    const data = requestObject();
    if (Platform.OS === 'web') {
      token = await generatePushNotificationsTokenForWeb();
    }
    if (token) {
      data.firebaseToken = token;
    }
    loginWithOtp({ data });
  };

  useEffect(() => {
    if (!OtpLoading) {
      if (otpData && !otpError) {
        setIsResend(false);
        setSentOtp(true);
        setErrorObj({});
        setTimeLeft(30);
        return;
      }
      if (!OtpLoading && otpError) {
        if (otpError) {
          sendMessage({
            message: otpError?.response?.data?.message,
            type: 'error',
          });
        }
        return;
      }
    }
  }, [otpData, OtpLoading]);

  useEffect(() => {
    if (loginError) {
      addMessage({
        message: loginError.response?.data.message,
        type: 'error',
      });
      return;
    }
    if (otpLoginData) {
      setToken(otpLoginData.accessToken);
    }
  }, [loginError, otpLoginData, setToken]);

  useEffect(() => {
    if (timeLeft === 0) {
      setIsResend(true);
    }
    if (otpSent && timeLeft !== 0) {
      const interval = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 1);
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [isResend,timeLeft]);

  const googleLoginSuccess = (data: any) => {
    setToken(data.accessToken);
  };

  const googleLoginError = () => {
    addMessage({ message: '', type: 'error' });
  };

  return (
    <View
      style={sx({
        margin: 3,
        flex: 1,
      })}
    >
      <TextField
        style={sx({
          borderColor: '#DADADA',
          height: 40,
          marginBottom: 3,
        })}
        placeholder={Constants?.expoConfig?.extra?.loginStrategy === 'tcs' ? "Username" : "Mobile No./Emails"}
        value={selectedEditObj.userInput}
        label={Constants?.expoConfig?.extra?.loginStrategy === 'tcs' ? "Username*" : 'Mobile No./Email.*'}
        innerInputLabel={!!selectedEditObj.userInput}
        onChangeText={(value) => updateValue('userInput', value)}
        helperText={errorObj.userInput}
        error={!!errorObj.userInput}
        disabled={signInWithOTP && otpSent}
      />
      {signInWithOTP && otpSent ? (
        <TextField
          style={sx({
            borderColor: '#DADADA',
            height: 40,
            marginBottom: 2,
          })}
          editable={otpSent}
          placeholder="Enter OTP*"
          value={selectedEditObj.otp}
          keyboardType="numeric"
          onChangeText={(value) =>
            updateValue('otp', value.replace(/[^0-9]/g, ''))
          }
          helperText={errorObj.otp}
          label={'Enter OTP*'}
          innerInputLabel={!!selectedEditObj.otp}
          error={!!errorObj.otp}
        />
      ) : (
        <></>
      )}

      {!signInWithOTP ? (
        <TextField
          style={sx({
            borderColor: '#DADADA',
            height: 40,
            marginBottom: 2,
          })}
          editable={!signInWithOTP}
          placeholder="Password"
          secureTextEntry={showPassword ? true : false}
          value={selectedEditObj.password}
          onChangeText={(value) => updateValue('password', value)}
          label={'Password*'}
          innerInputLabel={!!selectedEditObj.password}
          helperText={errorObj.password}
          error={!!errorObj.password}
          onKeyPress={(event) => {
            if (event.nativeEvent.key == 'Enter') handleSignin();
          }}
          endIcon={
            <Button
              variantType="text"
              style={sx({ marginRight: 1, borderRadius: 50, width: 50 })}
              onPress={() => setShowPassword(!showPassword)}
            >
              {showPassword ? (
                <Icon name="passwordShow" />
              ) : (
                <Icon name="passwordHide" />
              )}
            </Button>
          }
        />
      ) : (
        <></>
      )}

      <View
        style={[
          sx({
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: 'row',
            marginBottom: 2,
            height: 50,
          }),
        ]}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <CheckBox
            size={20}
            checked={signInWithOTP}
            onChange={() => {
              setSignInWithOTP(!signInWithOTP);
              setIsResend(false);
              setSentOtp(false);
            }}
          />
          <Text style={{ marginLeft: 5 }}>Sign in with a OTP</Text>
        </View>
        <Button
          style={[
            sx({ margin: 0, paddingLeft: 0, paddingRight: 0, minWidth: 120 }),
            {
              paddingHorizontal: 0,
              alignItems: 'flex-end',
            },
          ]}
          variantType="text"
          onPress={() =>
            push(
              `/account/forget-password${continueUrl ? `?continue=${continueUrl}` : ''
              }`
            )
          }
        >
          Forgot Password
        </Button>
      </View>

      {signInWithOTP && otpSent ? (
        <View
          style={{
            height: 47,
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <Text
            style={[
              sx({
                color: '$secondary',
                whiteSpace: 'nowrap',
                marginRight: 1,
              }),
            ]}
          >
            {otpData?.message ? 'OTP send successfully. ' : ''}
          </Text>

          <Text>
            {timeLeft > 0 ? `${timeLeft} seconds` : "Didn't get the code?"}
          </Text>

          <Button
            disabled={!isResend}
            style={Platform.select({
              native: {
                width: 110,
                margin: 0,
                textDecoration: 'underline',
              },
              web: {
                textDecoration: 'underline',
                margin: 0,
              },
            })}
            variantType="text"
            onPress={() => handleSentOtp(true)}
          >
            Resend
          </Button>
        </View>
      ) : (
        <></>
      )}

      {!signInWithOTP ? (
        <Button
          style={[
            sx({
              height: [47],
              marginBottom: 3,
              width: '100%',
            }),
          ]}
          onPress={() => handleSignin()}
          loading={loading}
          variantColor="primary"
        >
          Sign In
        </Button>
      ) : (
        <></>
      )}

      {signInWithOTP && otpSent ? (
        <Button
          style={[
            sx({
              height: [47],
              marginBottom: 3,
              width: '100%',
            }),
          ]}
          onPress={() => otpLogin()}
          loading={loginLoading}
          variantColor="primary"
        >
          Verify and proceed
        </Button>
      ) : (
        <></>
      )}

      {signInWithOTP && !otpSent ? (
        <Button
          style={[
            sx({
              height: [47],
              marginBottom: 3,
              width: '100%',
            }),
          ]}
          onPress={() => handleSentOtp()}
          loading={OtpLoading}
          variantColor="primary"
        >
          Send OTP
        </Button>
      ) : (
        <></>
      )}

      <GoogleSignIn
        onSuccess={googleLoginSuccess}
        onError={googleLoginError}
        buttonText="Sign in with Google"
        startText=""
      />

    </View>
  );
}

const mapStateToProps = (state: any) => ({
  user: getUser(state).user,clientConfig: getClientConfig(state).clientConfig,
});

export const SignIn = connect(mapStateToProps, {
  ...userAction,
  ...tokenAction,
  ...toastAction,
  ...welcomePopupAction,
})(UserSignInComponent);
