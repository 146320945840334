import React, { useEffect } from 'react';
import * as Google from 'expo-auth-session/providers/google';
import { Button } from '../button/common-button';
import { Image, Text, useSx, View } from 'dripsy';
import { makeUseAxiosHook } from 'app/redux/store';
import { config } from 'app/redux/config';
import { useFcmWebNotification } from 'app/provider/firebase-messaging';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { getClientConfig } from '../../redux/memoizedSelectors';

interface ParamData {
  socialToken?: string;
  firebaseToken?: string;
  clientId?: string;
}

export const GoogleSignIn = ({
  onSuccess,
  onError,
  startText='Signin with',
  buttonText= 'Google'
}: {
  onSuccess: (_data: object) => any
  onError: (_error: object) => any
  startText?:string
  buttonText?:string
}) => {
  const { clientConfig } = useSelector(getClientConfig);

  const domain =
    Platform.OS !== 'web'
      ? config.baseUrl
      : typeof window !== 'undefined'
      ? window.location.origin.replace('www.', '')
      : '';
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [request, response, promptAsync] = Google.useIdTokenAuthRequest(Platform.OS === 'web' ? {
    webClientId: config.googleWebClientId,
    redirectUri: `${domain}/account/login-successful`,
    scopes: [
      'email',
      'profile',
      'openid'
    ]
  } : {
    expoClientId:config.expoClientId,
    iosClientId:clientConfig.googleIOSClientId || config.googleIOSClientId,
    androidClientId:clientConfig.googleAndroidClientId || config.googleAndroidClientId,
    scopes: [
      'email',
      'profile',
      'openid'
    ]
  });

  const { generatePushNotificationsTokenForWeb } = useFcmWebNotification();
  const sx = useSx();

  const [{ data, loading, error }, verifyGoogleToken] = makeUseAxiosHook(
    'sso',
    {
      manual: true,
    }
  )({
    url: 'verify-google-token',
    method: 'POST',
  });

  React.useEffect(() => {
    if (response?.type === 'success') {
      const handleSignin = async () => {
        const { params } = response;
        let token : string | undefined = undefined;
        const paramData: ParamData = {
          socialToken: params?.id_token,
          clientId: clientConfig?.clientId
        };
        // if(Platform.OS === 'web') {
          token = await generatePushNotificationsTokenForWeb();
        // }
        if(token) {
          paramData.firebaseToken = token;
        }
        verifyGoogleToken({ data: paramData });
      };
      handleSignin();
    }
  }, [response]);

  useEffect(() => {
    if (error) {
      onError(error);
      return;
    }
    if (data) {
      onSuccess(data);
    }
  }, [data, error]);

  return (
    <View
      style={[
        sx({
          flex: 1,
          padding: 1,
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
        }),
      ]}
    >
      <Text
        style={[
          sx({
            color: '$pColor',
          }),
        ]}
      >
        {startText}
      </Text>
      <View>
        <Button
          loading={loading}
          startIcon={
            <Image
              resizeMode={'contain'}
              style={[sx({ width: [20], height: [20] })]}
              source={require('../../assets/google.png')}
              resizeMethod={'scale'}
            />
          }
          variantType="outlined"
          variantColor="primary"
          style={{ minWidth: 200 }}
          onPress={() => {
            promptAsync();
          }}
        >
          {buttonText}
        </Button>
      </View>
    </View>
  );
};
