import { Action, Dispatch } from 'redux';
import { SET_TOKEN } from '../constants';

export const setToken =
(token:string) =>{
  return (dispatch: Dispatch): Action =>
  dispatch({
    type: SET_TOKEN,
    payload:token
  });
};