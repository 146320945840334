import { Image, Pressable, ScrollView, useSx, View } from 'dripsy';
import React from 'react';
import { connect, useSelector } from 'react-redux';
import * as UserAction from 'app/redux/actions/user.action';
import { ImageBackground, KeyboardAvoidingView, Platform, SafeAreaView } from 'react-native';
import { useRouter } from 'solito/router';
import { useDim } from 'app/redux/hooks/useDim';
import { Gradient } from 'dripsy/gradient';
import { getClientConfig, getUser } from '../../redux/memoizedSelectors';

const AuthenticationComponentLayout = ({ children }: { children: any }) => {
  const { clientConfig } = useSelector(getClientConfig);

  const sx = useSx();
  const { push } = useRouter();
  const { width,height } = useDim();
  return (
    <KeyboardAvoidingView style={{ flex: 1 }} enabled>
      <ScrollView
        contentContainerStyle={Platform.select({
          web: {
            flex: 1,
            minHeight: height,
            height: '100%',
          },
        })}
      >
        <SafeAreaView
          style={sx({
            flex: 1,
            // backgroundColor:'#',
            flexDirection: ['column', 'column', 'column', 'row', 'row'],
          })}
        >
          <View
            style={sx({
              maxWidth: ['100%', '100%', '100%', width],
              minHeight: 275,
            })}
          >
            <Gradient
              colors={['$primary', '$primary']}
              style={sx({
                height: '100%',
                width: '100%',
                position: 'absolute',
                opacity: 0.5,
              })}
            />

            <ImageBackground
              source={require('../../assets/left-panel-bg.png')}
              style={sx({
                height: ['130%', '140%', '150%', '100%'],
                width: '100%',
                position: 'absolute',
                opacity: 0.2,
                alignItems: 'flex-end',
              })}
              resizeMode={'cover'}
            >
              <View
                style={sx({
                  height: '100%',
                  width: '100%',
                  position: 'absolute',
                  // background:
                  //   'linear-gradient(360deg, #06A2A0 0%, rgba(56, 131, 189, 0.0001) 100%)',
                })}
              />
            </ImageBackground>

            <View
              style={sx({
                flex: 1,
                paddingY: [3],
                paddingRight: [1, 1, 2],
                paddingLeft: [1, 1, 2],
                alignItems: ['center', 'center', 'center', 'flex-start'],
              })}
            >
              <View
                style={sx({
                  alignItems: ['center', 'center', 'center'],
                  justifyContent: 'center',
                  flex: 1,
                  ...(Platform.OS === 'web'
                    ? { width: ['100vw', '100vw', '35vw'] }
                    : {}),
                })}
              >
                <Pressable
                  onPress={() => {
                    if (Platform.OS === 'web') {
                      push('/');
                    } else {
                      return;
                    }
                  }}
                >
                  <Image
                    resizeMode={'contain'}
                    style={Platform.select({
                      web: sx({
                        width: ['90vw', '35vw', '35vw'],
                        height: '30vh',
                      }),
                      native: sx({
                        width: 0.9 * width,
                        height: 0.3 * height,
                      }),
                    })}
                    source={{ uri: clientConfig?.logo }}
                  />
                </Pressable>
              </View>
            </View>
          </View>

          <View
            style={sx({
              backgroundColor: '#f2f7ff',
              justifyContent: ['flex-start', 'center'],
              alignItems: 'center',
              flex: 1,
              paddingRight: [1, 1, 2],
              paddingLeft: [1, 1, 2],
            })}
          >
            {children}
          </View>
        </SafeAreaView>
      </ScrollView>
    </KeyboardAvoidingView>
  );
};

// const mapStateToProps = ({ user }: any) => ({
//   user,
// });

const AccountLayout = connect(getUser, {
  ...UserAction,
})(AuthenticationComponentLayout);

export default AccountLayout;
