import React from 'react';
import { UserSignIn } from 'app/features/account/signin';
import { SeoHelper } from 'app/components/seo-helper';
import { store } from 'app/redux/store';

const UserSignInPage = (props) => {
  const globalStoreData = store.getState();
  const { clientConfig } = globalStoreData || {};
  return (
    <>
      <SeoHelper
        title={`Signin to ${clientConfig?.name} (Online Student & Teachers Community)`}
        description={
          `Signin to ${clientConfig?.name} to download study materials and notes online according to Your Course and universitites.`
        }
        image={
          'https://upload-production-s3.s3.ap-south-1.amazonaws.com/lecturenotes-assets/ln-og-image.png'
        }
        keywords={
          'Download lecture notes, Online Student notes, Teachers notes, Download study materials online, notes online, course notes online, universities notes online'
        }
        ogTitle={
          `${clientConfig?.name} | Online Notes Handwritten class Notes Old Year Exam Study Materials`
        }
        canonical="https://lecturenotes.in/aboutus"
        ogDescription={
          'Engineering Notes and University previous year questions for B.Tech in CSE, Mechanical, Electrical, Electronics, Civil available for free download in PDF format at lecturenotes.in, Engineering Class handwritten notes, exam preparation notes, topper written notes.'
        }
      ></SeoHelper>
      <UserSignIn {...props} />
    </>
  );
};

export default UserSignInPage;
