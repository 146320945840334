import { Pressable, Text, View, useSx } from 'dripsy';
import React, { ReactNode } from 'react';
import { Platform } from 'react-native';
import { useSelector } from 'react-redux';
import { useRouter } from 'solito/router';
import { getClientConfig } from '../../redux/memoizedSelectors';

interface LayoutProps {
  children: ReactNode;
  continueUrl?: string;
  type: 'signin' | 'signup';
}

export const SignInSignUpLayout = ({
  children,
  continueUrl,
  type,
}: LayoutProps) => {
  const { clientConfig } = useSelector(
    getClientConfig
  );
  const sx = useSx();
  const { push } = useRouter();
  return (
    <View
      style={[sx({
        marginX: [0, 0, 10],
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: 0,
        height:'auto',
        marginTop: [10, 10, 10, 20],
        marginBottom: [0, 20, 20, 20, 20],
        width: ['100%'],
        maxWidth: [430],
        shadowColor: '#000',
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        borderRadius: 8,
        backgroundColor: '$white',
      }),
       Platform.select({
        native:{
          flex:1,
          width:'100%',
        }
      })]
       
}
    >
     
        <View
          style={sx({
            minHeight: [47, 52],
            width: '100%',
            flexDirection: 'row',
            alignItems:'center',
            justifyContent:'space-around',
          })}
        >
          <Pressable
            onPress={() =>
              push(
                `/account/signin${
                  continueUrl ? `?continue=${continueUrl}` : ''
                }`
              )
            }
            style={{
              flex:1
            }}
          >
            <View
              style={sx({
                borderBottomColor: type == 'signin' ? '$primary' : '#DADADA',
                borderBottomWidth: type == 'signin' ? 3 : 1,
                alignItems: 'center',
                paddingY:10,
              })}
            >
              <Text
                style={sx({
                  color: type == 'signin' ? '$primary' : 'gray',
                  fontWeight: '600',
                  fontSize: [14, 18],
                })}
              >
                Sign In
              </Text>
            </View>
          </Pressable>
 {clientConfig?.registration && <Pressable
            onPress={() =>
              push(
                `/account/signup${
                  continueUrl ? `?continue=${continueUrl}` : ''
                }`
              )
            }
            style={{
              flex:1
            }}
          >
            <View
              style={sx({
                borderBottomColor: type == 'signup' ? '$primary' : '#DADADA',
                borderBottomWidth: type == 'signup' ? 3 : 1,
                alignItems: 'center',
                paddingY:10,
              })}
            >
              <Text
                style={sx({
                  color: type == 'signup' ? '$primary' : 'gray',
                  fontWeight: '600',
                  fontSize: [14, 18],
                })}
              >
                Sign Up
              </Text>
            </View>
          </Pressable>}
        </View>
     
       <View 
        style={sx({
          flex:1,
          width: '100%',
        })}
       >
       {children}
       </View>
    </View>
  );
};
