import React, { useEffect, useMemo, useState } from 'react';
import { Image, View, Text, ScrollView, useSx, Box } from 'dripsy';
import { StyleSheet, ImageBackground } from 'react-native';
import { Button } from 'app/components/button/common-button';
import * as userAction from '../../../redux/actions/user.action';
import * as tokenAction from '../../../redux/actions/token.action';
import * as toastAction from '../../../redux/actions/toast.action';
import * as welcomePopupAction from '../../../redux/actions/welcomePopup.action';
import { connect } from 'react-redux';
import { validEmail, validPhone } from 'app/util/helper';
import { useFcmWebNotification } from 'app/provider/firebase-messaging';
import { createParam } from 'solito';
import { useRouter } from 'solito/router';
const { useParam } = createParam<{ continue: string }>();
import userLoginSuccess from 'app/redux/hooks/user-login-success';
import { TextField } from 'app/components/text-field';
import { Dimensions } from 'react-native';
import { Platform } from 'react-native';
import { Icon } from 'app/components/icon/icon';
import { useDim } from 'app/redux/hooks/useDim';
import { getClientConfig, getUser } from '../../../redux/memoizedSelectors';
import Constants from 'expo-constants';

const screenWidth = Dimensions.get('window').width;

const TypographyArray = [
  'Elevate education in Andhra Pradesh teacher training implementing a comprehensive Teacher Training Support System for seamless tracking and data-driven decision-making',
  'The TTSS (Teacher Training Support System) is utilized for maintaining records of the training sessions.',
  'Teachers are central users, registering for professional development courses, actively participating in training, coursework, assignments, and assessments.',
  'The State is leading an initiative to digitize training data, developing a comprehensive platform for end-to-end tracking, user-friendly access to materials, personalized learning paths, data analytics for progress tracking, and technology integration to enhance the training experience.',
  'The TTSS platform enhances state visibility on teacher training within the SALT project and other initiatives.',
];

function StlLoginComponent({ user, clientConfig, showWelcomePopup, login }) {
  const [continueUrl] = useParam('continue');
  const sx = useSx();
  const { width } = useDim();
  const isMobile = useMemo(() => {
    return Platform.OS === 'android' || screenWidth < 400;
  }, [screenWidth]);

  const backgroundImages = [
    require('../../../assets/stl/banner1.jpeg'),
    require('../../../assets/stl/banner2.jpg'),
    require('../../../assets/stl/banner3.jpg'),
    require('../../../assets/stl/banner4.jpg'),
    require('../../../assets/stl/banner5.jpeg'),
  ];
  const [selectedEditObj, setSelectedEditObj] = useState({
    email: '',
    password: '',
    phone: '',
    otp: '',
    userInput: '',
  });

  const [showPassword, setShowPassword] = useState<any>(true);
  const [errorObj, setErrorObj] = useState<any>({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [bgIndex, setBgIndex] = useState(0);

  const { generatePushNotificationsTokenForWeb } = useFcmWebNotification();
  const Router = useRouter();
  const { push, replace } = Router;

  const updateValue = (key: string, value: any) => {
    setSelectedEditObj({ ...selectedEditObj, [key]: value });
  };

  const validateForm = (validateObj: userAction.LoginReq) => {
    let errorList: any = {};

    if (!selectedEditObj.userInput) {
      errorList.userInput = 'Please enter your Email / Phone Number';
    }

    if (!validateObj.type && selectedEditObj.userInput) {
      errorList.userInput = 'Please enter valid email or phone number.';
    }

    if (!validateObj.password) {
      errorList.password = 'Please enter your password';
    }
    if (Object.keys(errorList).length == 0) {
      setErrorObj({});
      return true;
    } else {
      setErrorObj(errorList);
      errorList = {};
      return false;
    }
  };
  const [loading, setLoading] = useState(false);

  const handleSignin = async () => {
    const loginPayload: userAction.LoginReq = {
      strategy: Constants?.expoConfig?.extra?.loginStrategy || 'local',
      password: selectedEditObj.password,
    };

    let token: string | undefined = undefined;

    // if (Platform.OS === 'web') {
    token = await generatePushNotificationsTokenForWeb();
    // }

    if (Constants?.expoConfig?.extra?.loginStrategy === 'tcs') {
      loginPayload.strategy = 'tcs';
      loginPayload.type = 'username';
      loginPayload.username = selectedEditObj.userInput.trim();
    }
    else if (validEmail(selectedEditObj.userInput?.trim())) {
      loginPayload.email = selectedEditObj.userInput.trim();
      loginPayload.type = 'email';
    } else if (
      validPhone(selectedEditObj.userInput?.trim()) ||
      validPhone('+91' + selectedEditObj.userInput?.trim())
    ) {
      loginPayload.phone = selectedEditObj.userInput.trim();
      loginPayload.type = 'phone';
      loginPayload.strategy = 'local-phone';
    } else {
      loginPayload.type = '';
    }

    // else {
    //   loginPayload.username = selectedEditObj.userInput.trim();
    //   loginPayload.type = 'username';
    //   loginPayload.strategy = 'local-username';
    // }

    if (token) {
      loginPayload.firebaseToken = token;
    }

    if (!validateForm(loginPayload)) return;
    setLoading(true);
    login(loginPayload);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % TypographyArray.length);
    }, 3000);
    const bgInterval = setInterval(() => {
      setBgIndex((prevIndex) => (prevIndex + 1) % backgroundImages.length);
    }, 3000);
    return () => {
      clearInterval(intervalId);
      clearInterval(bgInterval);
    };
  }, []);

  useEffect(() => {
    if (user) {
      if (user.error) {
        setLoading(false);
        setErrorObj({ password: user.error });
        return;
      }
      if (user?._id) {
        setLoading(false);
        // if continueUrl is '/' i.e home, then after login redirect to dashboard
        if (!user.onboardingSkip && !user.preferenceUpdated) {
          if (clientConfig.onboarding) {
            replace(
              `/account/on-boarding${
                continueUrl
                  ? `?continue=${
                      continueUrl === '/' ? '/dashboard' : continueUrl
                    }`
                  : ''
              }`
            );
          } else {
            replace('/dashboard');
          }
        } else {
          if (user?.firstLogin === false) {
            showWelcomePopup();
          }
          const isSearchUrl = continueUrl?.includes('search/');
          const searchUrl: any =
            continueUrl?.split('search/')?.[
              continueUrl?.split('search')?.length - 1
            ];
          userLoginSuccess({
            Router,
            continueUrl: isSearchUrl
              ? `/search/${encodeURIComponent(searchUrl)}`
              : continueUrl === '/'
              ? '/dashboard'
              : continueUrl,
          });
        }
      }
    }
  }, [user]);
  const { height } = useDim()
  return (
    <View style={[styles.fullSize, {minHeight: height}]}>
      <ImageBackground
        source={backgroundImages[bgIndex]}
        style={styles.background}
        imageStyle={styles.bgImageStyle}
      >
        <ImageBackground
          style={sx({ opacity: 0.3, width: '100%', height: '100%' })}
          source={require('../../../assets/stl/backgroundImg.jpg')}
        />
      </ImageBackground>
      <ScrollView
        contentContainerStyle={sx({
          flex: 1,
          justifyContent: ['center', 'space-around'],
          alignItems: 'center',
          flexDirection: 'row',
          padding: 20,
          margin: 20,
          maxWidth: ['100%', '100%', '100%', width],
        })}
      >
        {!isMobile || screenWidth > 410 ? (
          <View style={styles.logoContainer}>
            <Box
              style={sx({
                width: 200,
                height: 100,
                borderRadius: 20,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                background: 'white',
              })}
            >
              <Image
                source={require('../../../assets/stl/logo1.png')}
                style={sx({ ...styles.logo })}
              />
            </Box>
            <Text style={sx(styles.headerText)}>
            SALT Integrated Training & Learning Management System Government Of Andhra Pradesh
            </Text>
            <Text style={sx(styles.subheaderText)}>
              {TypographyArray[currentIndex]}
            </Text>
          </View>
        ) : (
          <></>
        )}
        <View
          style={sx({
            alignItems: 'center',
            padding: [50, 30],
            borderRadius: 20,
            backgroundColor: 'white',
            width: 400,
            height: 410,
            shadowColor: '#000',
            shadowOffset: {
              width: 3,
              height: 3,
            },
            shadowOpacity: 0.52,
            shadowRadius: 2.22,
            elevation: 20,
          })}
        >
          <Box
            style={sx({
              width: 100,
              borderRadius: 50,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'relative',
              bottom: 50,
              borderWidth: 20,
              borderColor: 'rgba(158, 150, 150, .5)',
            })}
          >
            <Image
              source={require('../../../assets/stl/logo2.png')}
              style={sx({
                borderRadius: 50,
                width: 100,
                height: 100,
                position: 'absolute',
                borderWidth: 10,
                borderColor: 'white',
                backgroundColor: 'white',
              })}
            />
          </Box>
          <Text style={sx(styles.loginHeader)}>Login</Text>
          <TextField
            style={styles.input}
            placeholder={Constants?.expoConfig?.extra?.loginStrategy === 'tcs' ? "Username" : "Enter your Mobile No./Emails"}
            keyboardType="email-address"
            label={Constants?.expoConfig?.extra?.loginStrategy === 'tcs' ? "Username" : 'Mobile No./Email.*'}
            value={selectedEditObj.userInput}
            innerInputLabel={!!selectedEditObj.userInput}
            onChangeText={(value) => updateValue('userInput', value)}
            helperText={errorObj.userInput}
            error={!!errorObj.userInput}
          />
          <TextField
            label={'Password*'}
            style={styles.input}
            placeholder="Password"
            secureTextEntry={showPassword ? true : false}
            onChangeText={(value) => updateValue('password', value)}
            helperText={errorObj.password}
            error={!!errorObj.password}
            innerInputLabel={!!selectedEditObj.password}
            value={selectedEditObj.password}
            onKeyPress={(event) => {
              if (event.nativeEvent.key == 'Enter') handleSignin();
            }}
            endIcon={
              <Button
                variantType="text"
                style={sx({ marginRight: 1, borderRadius: 50, width: 50 })}
                onPress={() => setShowPassword(!showPassword)}
              >
                {showPassword ? (
                  <Icon name="passwordShow" />
                ) : (
                  <Icon name="passwordHide" />
                )}
              </Button>
            }
          />
          <Button
            style={styles.forgotPassword}
            variantType="text"
            onPress={() =>
              push(
                `/account/forget-password${
                  continueUrl ? `?continue=${continueUrl}` : ''
                }`
              )
            }
          >
            <Text style={[styles.forgotPasswordText,sx({ color:'$primary' })]}>Forgot Password?</Text>
          </Button>
          <Button
            style={sx({
              width: [100, 330],
              height: [40],
              borderRadius: 6,
              backgroundColor: '$primary',
              padding: 10,
              // marginTop:20
            })}
            textStyle={sx({
              fontSize: [12, 17],
              color: 'white',
            })}
            variantColor="secondary"
            variantType="contained"
            loadingIndicatorSize={18}
            onPress={() => handleSignin()}
            loading={loading}
          >
            Log In
          </Button>
        </View>
      </ScrollView>
    </View>
  );
}
const styles = StyleSheet.create({
  fullSize: { flex: 1, height: '100%' },
  background: { flex: 1, position: 'absolute', width: '100%', height: '100%' },
  bgImageStyle: { opacity: 0.8 },
  transparentLayer: { flex: 1, backgroundColor: 'transparent' },
  logoContainer: {
    flex: 1,
    alignItems: 'flex-start',
    marginBottom: 20,
    maxWidth: 600,
    height: 400,
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  logo: {
    borderRadius: 11,
    width: 200,
    height: 180,
    position: 'absolute',
    resizeMode: 'contain',
  },
  headerText: {
    fontSize: 24,
    fontWeight: '800',
    color: 'white',
    marginTop: 3,
    marginBottom: 10,
  },
  subheaderText: {
    fontSize: 18,
    fontWeight: '400',
    color: 'white',
  },
  bodyText: { color: 'white', marginTop: 10, textAlign: 'center' },
  logo2: { width: 100, height: 100, bottom: 40 },
  loginHeader: {
    fontSize: 30,
    fontWeight: '800',
    textAlign: 'center',
    margin: 1,
    marginTop: -20,
  },
  welcomeText: { textAlign: 'center', marginBottom: 20 },
  input: {
    width: '100%',
    padding: 10,
    marginVertical: 8,
    backgroundColor: '#f0f0f0',
    borderRadius: 6,
    marginTop: 10,
  },
  forgotPassword: {
    marginTop: 10,
    width: 200,
    marginLeft: 200,
  },
  forgotPasswordText: {
    textAlign: 'right',
    // width: '100%',
    // color: '#007AFF',
    fontSize: 15,
    marginTop: 20,
  },
});
const mapStateToProps = (state) => ({
  user: getUser(state).user,
  clientConfig: getClientConfig(state).clientConfig,
});

export const StlLogin = connect(mapStateToProps, {
  ...userAction,
  ...tokenAction,
  ...toastAction,
  ...welcomePopupAction,
})(StlLoginComponent);
