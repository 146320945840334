import { Platform } from 'react-native';
import { navigationRef } from '../../../provider/navigation/RootNavigation';

const goToPath = (Router: any, continueUrl: any, defaultUrl: any) => {
  if (continueUrl) {
    Router.replace(continueUrl);
  } else {
    Router.replace(defaultUrl);
  }
};

const userLoginSuccess = ({
  Router,
  continueUrl,
  defaultUrl = '/dashboard',
}: any) => {
  if (Platform.OS === 'web') {
    if (Router) {
      goToPath(Router, continueUrl, defaultUrl);
    }
  } else {
    if (Router && navigationRef.isReady()) {
      goToPath(Router, continueUrl, defaultUrl);
    }
  }

};

export default userLoginSuccess;
