import Ionicons from '@expo/vector-icons/Ionicons';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import { useSx, View } from 'dripsy';
import React, { ReactNode, useEffect } from 'react';
import { Platform, TextInputProps, TextInput, ViewStyle } from 'react-native';
import { P } from '../typography/p';
import { I18Text, useI18 } from 'app/provider/i18-provider';

interface TextFieldProps extends TextInputProps {
  showSearchIcon?: boolean;
  showRightArrowIcon?: boolean;
  helperText?: string;
  labelStyle?: ViewStyle;
  label?: string;
  innerInputLabel?: boolean;
  error?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
  editable?: boolean;
  searchIconStyle?: ViewStyle;
  inputStyle?: any;
  autoFocus?: boolean;
  disabled?:boolean
}

export function TextField({
  style,
  showSearchIcon = false,
  showRightArrowIcon = false,
  helperText,
  labelStyle,
  label,
  innerInputLabel = false,
  error,
  selectionColor,
  placeholderTextColor,
  underlineColorAndroid,
  startIcon,
  endIcon,
  editable = true,
  searchIconStyle,
  inputStyle = {},
  autoFocus = false,
  ...props
}: TextFieldProps) {
  const sx = useSx();
  useEffect(() => {
    //
  }, [selectionColor, placeholderTextColor, underlineColorAndroid]);
  const inputRef = React.useRef<any>('');
  const { getLabel } = useI18();
  useEffect(() => {
    if (autoFocus) {
      setTimeout(() => {
        inputRef?.current && inputRef.current.blur();
        inputRef?.current && inputRef.current.focus();
      }, 100);
    } else {
      inputRef?.current && inputRef.current.blur();
    }

  }, [autoFocus]);

  return (
    <View
      style={[
        sx({
          alignSelf: 'stretch',
          backgroundColor: !editable ? '$disabled' : '$white',
          borderColor: !editable ? '$disabled' : error ? '$red' : '$secondary',
          borderStyle: 'solid',
          borderWidth: 1,
          borderRadius: 5,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
        }),
        style,
      ]}
    >
      {innerInputLabel ? (
        <P
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[
            sx({
              padding: 1,
              color: '#0000007E',
              position: 'absolute',
              top: -14,
              left: 1,
              right: 0,
              fontSize: 14,
            }),
            labelStyle,
          ]}
        >
          <I18Text
            style={sx({
              backgroundColor: 'white',
              paddingX: 1,
              fontWeight: '500',
            })}
          >
            {label}
          </I18Text>
        </P>
      ) : null}

      {showSearchIcon || startIcon ? (
        startIcon ? (
          startIcon
        ) : (
          <Ionicons
            name="search"
            size={20}
            style={[
              sx({
                color: '$primary',
                marginLeft: 1,
              }),
              searchIconStyle,
            ]}
          />
        )
      ) : null}
      <TextInput
        autoFocus={autoFocus}
        editable={editable}
        ref={inputRef}
        style={[
          sx({
            flex: 1,
            color: !editable ? 'grey' : '#000',
            padding: 2,
            height: '100%',
            borderRadius: 5,
          }),
          Platform.OS === 'web'
            ? sx({
              outline: 'none',
            })
            : {},
          inputStyle,
        ]}
        {...props}
        placeholder={getLabel(props?.placeholder)}
      />
      {showRightArrowIcon || endIcon ? (
        endIcon ? (
          endIcon
        ) : (
          <MaterialIcons
            name="keyboard-arrow-right"
            size={25}
            style={[
              sx({
                color: '$primary',
              }),
            ]}
          />
        )
      ) : null}

      {helperText ? (
        <P
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[
            sx({
              padding: 1,
              color: error ? '$red' : '$primary',
              position: 'absolute',
              bottom: -12,
              left: 2,
              right: 2,
            }),
          ]}
        >
          <I18Text style={sx({ backgroundColor: 'white' })}>{helperText}</I18Text>
        </P>
      ) : null}
    </View>
  );
}
